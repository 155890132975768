<template>
  <main
    id="mind-right-menu"
    :style="{ left: rightMenuSite.left + 'px', top: rightMenuSite.top + 'px' }"
    @mousemove="$emit('continuousShowRightMenu')"
    @mouseleave="$emit('timingHideRightMenu')"
    @contextmenu.stop.prevent=""
  >
    <div
      class="right-menu-item"
      v-for="(item, index) in rightMenuList"
      :key="index"
      @click="rightMenuFun(item.type)"
      @contextmenu.stop.prevent="rightMenuFun(item.type)"
    >
      <template v-if="item.type == 'displayMode'">
        <img
          :src="viewMode ? item.icon2 : item.icon1"
          class="menu-item-icon"
          :alt="item.name"
        />
        <div class="menu-item-name">{{ item.name }}</div>
      </template>
      <template v-else-if="item.type == 'sort'">
        <img :src="item.iconUrl" class="menu-item-icon" :alt="item.name" />
        <div class="menu-item-name">{{ item.name }}</div>
        <img
          src="../../../assets/img/canvasMenu/menuTriange.png"
          class="icon-triange"
          alt="查看更多"
        />
        <section
          ref="sortMenu"
          class="sort-menu"
          :style="{ left: sortSite.left + 'px', top: sortSite.top + 'px' }"
        >
          <div
            class="sort-menu-item"
            v-for="item of sortOrderList"
            :key="item.value"
            @click="sortOrderChange(item.value)"
          >
            {{ item.label }}
          </div>
        </section>
      </template>
      <template v-else>
        <img :src="item.iconUrl" class="menu-item-icon" :alt="item.name" />
        <div class="menu-item-name">{{ item.name }}</div>
      </template>
    </div>
  </main>
</template>
<script>
import getString from "../../../common/lang/language";
import strings from "../../../common/lang/strings";
export default {
  props: [
    "viewMode",
    "rightMenuSite",
    "rightMenuSize",
    "sortOrderList",
    "myMindWidth",
    "myMindHeight",
  ],
  data() {
    return {
      strings,
      rightMenuList: [
        {
          id: 1,
          name: getString(strings.My_Mind_Create_Folder), //创建文件夹
          iconUrl: require("../../../assets/img/myMindIcon/rightMenuIcon/createFolder.svg"),
          type: "createFolder",
        },
        {
          id: 2,
          name: getString(strings.Create_Map), //创建导图
          iconUrl: require("../../../assets/img/myMindIcon/rightMenuIcon/createMap.svg"),
          type: "createMap",
        },
        {
          id: 3,
          name: getString(strings.Display_Mode), //显示模式
          icon1: require("../../../assets/img/myMindIcon/rightMenuIcon/listMode.svg"),
          icon2: require("../../../assets/img/myMindIcon/rightMenuIcon/iconMode.svg"),
          type: "displayMode",
        },
        {
          id: 4,
          name: getString(strings.Echart_Edit_Value_Sort), //排序
          iconUrl: require("../../../assets/img/myMindIcon/rightMenuIcon/sort.svg"),
          type: "sort",
        },
      ],
      sortSite: {
        left: 160,
        top: 0,
      }, //排序菜单位置
    };
  },
  methods: {
    getString(i) {
      return getString(i);
    },
    // 右键菜单按钮
    rightMenuFun(type) {
      this.$emit("rightMenuFun", type);
    },
    // 排序方式
    sortOrderChange(value) {
      this.$emit("sortOrderChange", value);
    },
  },
  watch: {
    rightMenuSite: {
      handler(newVal) {
        let sortMenuBox = this.$refs.sortMenu[0]; //排序菜单
        if (sortMenuBox) {
          let rightMenuLeft = newVal.left; //鼠标x轴
          let rightMenuTop = newVal.top; //鼠标y轴
          let rightMenuWidth = this.rightMenuSize.width; //菜单的宽度
          let rightMenuHeight = this.rightMenuSize.height; //菜单的高度
          let sortMenuWidth = sortMenuBox.offsetWidth; //排序菜单的宽度
          let sortMenuHeight = sortMenuBox.offsetHeight; //排序菜单的高度

          if (
            this.myMindWidth - rightMenuLeft >
            rightMenuWidth + sortMenuWidth
          ) {
            this.sortSite.left = rightMenuWidth;
          } else {
            this.sortSite.left = -sortMenuWidth;
          }
          if (
            this.myMindHeight - rightMenuTop >
            rightMenuHeight + sortMenuHeight
          ) {
            this.sortSite.top = 0;
          } else {
            let rightMenu = document.getElementById("mind-right-menu"); //菜单
            if (rightMenu) {
              let paddingBottom = parseInt(
                window.getComputedStyle(rightMenu)["padding-bottom"]
              ); //菜单下边距
              let child = rightMenu.children; //菜单子节点
              let index = this.rightMenuList
                .map((item) => item.type)
                .indexOf("sort"); //排序按钮的下标
              let sortItemHeight = child[index].offsetHeight; //排序按钮的高度
              this.sortSite.top = -(
                sortMenuHeight -
                paddingBottom -
                sortItemHeight
              );
              // console.log(index);
            }
          }
        }
      },
      deep: true,
    },
  },
};
</script>
<style lang='less' scoped>
#mind-right-menu {
  min-width: 160px;
  height: 188px;
  padding: 10px 0;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  position: absolute;
  left: 200px;
  top: 200px;
  z-index: 999;
  .right-menu-item {
    width: 100%;
    height: 42px;
    padding: 12px 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    &:hover {
      background-color: #edeff4;
    }
    &:hover .sort-menu {
      transition-delay: 0.1s;
      visibility: visible;
      opacity: 1;
    }
    .menu-item-icon {
      width: auto;
      display: block;
    }
    .menu-item-name {
      margin-left: 15px;
      font-size: 16px;
      color: #666;
      white-space: nowrap;
    }
    .icon-triange {
      margin: auto;
      position: absolute;
      right: 10px;
    }
    .sort-menu {
      min-width: 102px;
      padding: 10px 0;
      background: #ffffff;
      border: 1px solid #e0e0e0;
      border-radius: 8px;
      position: absolute;
      left: 160px;
      top: 0px;
      z-index: 9999;
      visibility: hidden;
      opacity: 0;
      transition-delay: 0.2s;

      .sort-menu-item {
        width: 100%;
        height: 42px;
        padding: 12px 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;
        color: #666;
        white-space: nowrap;
        &:hover {
          background-color: #ececec;
        }
      }
    }
  }
}
</style>